<template>
  <div class="p3outer">
    <div class="p3Title">管理平台架构</div>
    <img :src="require('@/assets/images/dataCenter/centerData.png')" class="part3-img" alt="">
    <!-- <div class="p3titlebar"></div>
    <div class="arrs">
      <img src="/img/dataCenter/箭头实心@2x.png" alt="" />
      <img src="/img/dataCenter/箭头实心@2x.png" alt="" />
      <img src="/img/dataCenter/箭头实心@2x.png" alt="" />
    </div>
    <ul class="p3ul1">
      <li class="li1">
        <img
          src="/img/dataCenter/数据服务化@2x.png"
          alt=""
          style="width: 103px"
        />
      </li>
      <li class="li2">
        <span
          ><img
            src="/img/dataCenter/数据共享@2x.png"
            alt=""
            style="width: 67px"
        /></span>
        <span
          ><img
            src="/img/dataCenter/大数据分析@2x.png"
            alt=""
            style="width: 83px"
        /></span>
        <span
          ><img
            src="/img/dataCenter/智能化应用@2x.png"
            alt=""
            style="width: 83px"
        /></span>
        <span
          ><img
            src="/img/dataCenter/数据化运营@2x.png"
            alt=""
            style="width: 83px"
        /></span>
      </li>
      <li class="li3">
        <img
          src="/img/dataCenter/数据资产化@2x.png"
          alt=""
          style="width: 103px"
        />
      </li>
      <li class="li4">
        <div class="li4_1">
          <img
            class="li4arr arr1"
            src="/img/dataCenter/箭头(红)@2x.png"
            alt=""
          />
          <div style="margin-top: 0px">
            <img
              src="/img/dataCenter/共享公式库@2x.png"
              alt=""
              style="width: 89px"
            />
          </div>

          <div>
            <span>
              <img src="/img/dataCenter/共享库@2x.png" alt="" />
            </span>
          </div>
          <div>
            <span>
              <img src="/img/dataCenter/公示库@2x.png" alt="" />
            </span>
          </div>
          <div class="lastp">
            <img
              src="/img/dataCenter/数据抽取@2x.png"
              alt=""
              style="width: 63px"
            />
            |
            <img src="/img/dataCenter/转换@2x.png" alt="" style="width: 32px" />
            |
            <img src="/img/dataCenter/加载@2x.png" alt="" style="width: 32px" />
          </div>
        </div>
        <div class="li4_2">
          <img class="li4arr" src="/img/dataCenter/箭头(红)@2x.png" alt="" />
          <div style="margin-top: 0px">
            <img
              src="/img/dataCenter/分析指标库@2x.png"
              alt=""
              style="width: 89px"
            />
          </div>
          <div>
            <span class="marginR10"
              ><img src="/img/dataCenter/分析指标模型库@2x.png" alt=""
            /></span>
            <span
              ><img src="/img/dataCenter/市级分析指标库@2x.png" alt=""
            /></span>
          </div>
          <div>
            <span class="marginR10"
              ><img src="/img/dataCenter/县级分析指标库@2x.png" alt=""
            /></span>
            <span
              ><img src="/img/dataCenter/部门分析指标库@2x.png" alt=""
            /></span>
          </div>
          <div class="lastp2">
            <span style="width: 336px"
              ><img
                src="/img/dataCenter/指标定义@2x.png"
                alt=""
                style="width: 63px" />
              |
              <img
                src="/img/dataCenter/数据抽取@2x.png"
                alt=""
                style="width: 63px" />
              |
              <img
                src="/img/dataCenter/实时推送@2x.png"
                alt=""
                style="width: 63px"
            /></span>
          </div>
        </div>
        <div class="li4_3">
          <img class="li4arr" src="/img/dataCenter/箭头(红)@2x.png" alt="" />
          <div style="margin-top: 0px">
            <img
              src="/img/dataCenter/主题数据库@2x.png"
              alt=""
              style="width: 89px"
            />
          </div>
          <div>
            <span class="marginR10"
              ><img
                src="/img/dataCenter/执法效能@2x.png"
                alt=""
                style="width: 64px"
            /></span>
            <span
              ><img
                src="/img/dataCenter/法律法规实施状况@2x.png"
                alt=""
                style="width: 127px"
            /></span>
          </div>
          <div>
            <span class="marginR10"
              ><img
                src="/img/dataCenter/职权履职@2x.png"
                alt=""
                style="width: 64px"
            /></span>
            <span
              ><img
                src="/img/dataCenter/综合执法@2x.png"
                alt=""
                style="width: 64px"
            /></span>
          </div>
          <div class="lastp3">
            <span
              ><img
                src="/img/dataCenter/主题定义@2x.png"
                alt=""
                style="width: 63px" />
              |
              <img
                src="/img/dataCenter/数据抽取@2x.png"
                alt=""
                style="width: 63px" />
              |
              <img
                src="/img/dataCenter/属性抽取@2x.png"
                alt=""
                style="width: 63px" />
              |
              <img
                src="/img/dataCenter/实时推送@2x.png"
                alt=""
                style="width: 63px"
            /></span>
          </div>
        </div>
      </li>
      <li class="li5">
        <div class="li5f1">
          <img
            class="margin16"
            src="/img/dataCenter/法律法规库@2x.png"
            alt=""
            style="width: 89px"
          />
          <div class="margin16">
            <span class="marginR10">
              <img
                src="/img/dataCenter/法律@2x.png"
                alt=""
                style="width: 31px"
              />
            </span>
            <span>
              <img
                src="/img/dataCenter/行政法规@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>
          </div>
          <div class="margin16">
            <span class="marginR10">
              <img
                src="/img/dataCenter/部门规章@2x.png"
                alt=""
                style="width: 63px"
              />
            </span>
            <span>
              <img
                src="/img/dataCenter/地方性法规@2x.png"
                alt=""
                style="width: 80px"
              />
            </span>
          </div>
          <div>
            <span class="marginR10">
              <img
                src="/img/dataCenter/地方性规章@2x.png"
                alt=""
                style="width: 80px"
              />
            </span>
            <span>
              <img src="/img/dataCenter/@2x.png" alt="" style="width: 12px" />
            </span>
          </div>
        </div>
        <div class="li5f2">
          <img
            class="margin16"
            src="/img/dataCenter/执法要素库@2x.png"
            alt=""
            style="width: 89px"
          />
          <div class="margin16">
            <span class="marginR10">
              <img
                src="/img/dataCenter/执法机关@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>
            <span class="marginR10">
              <img
                src="/img/dataCenter/执法主体@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>
            <span>
              <img
                src="/img/dataCenter/受委托组织@2x.png"
                alt=""
                style="width: 80px"
              />
            </span>
          </div>
          <div class="margin16">
            <span class="marginR10">
              <img
                src="/img/dataCenter/执法人员@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>
            <span class="marginR10">
              <img
                src="/img/dataCenter/执法对象@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>
            <span>
              <img
                src="/img/dataCenter/监督机关@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>
          </div>
          <div>
            <span class="marginR10">
              <img
                src="/img/dataCenter/监督人员@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>
            <span class="marginR10">
              <img
                src="/img/dataCenter/权责清单@2x.png"
                alt=""
                style="width: 64px"
              />
            </span>

            <span>
              <img
                src="/img/dataCenter/自由裁量基准@2x.png"
                alt=""
                style="width: 94px"
              />
            </span>
          </div>
        </div>
        <div class="li5f3">
          <img
            class="margin16"
            src="/img/dataCenter/执法行为库@2x.png"
            alt=""
            style="width: 89px"
          />
          <div class="margin16">
            <span
              ><img
                src="/img/dataCenter/执法检查@2x.png"
                alt=""
                style="width: 64px"
            /></span>
          </div>
          <div class="margin16">
            <span
              ><img
                src="/img/dataCenter/行政处罚@2x.png"
                alt=""
                style="width: 64px"
            /></span>
          </div>
          <div class="margin16">
            <span
              ><img
                src="/img/dataCenter/行政强制@2x.png"
                alt=""
                style="width: 64px"
            /></span>
          </div>
        </div>
        <div class="li5f4">
          <img
            class="margin16"
            src="/img/dataCenter/其它库@2x.png"
            alt=""
            style="width: 53px"
          />

          <div class="margin16">
            <span
              ><img
                src="/img/dataCenter/信用库@2x.png"
                alt=""
                style="width: 48px"
            /></span>
          </div>
          <div class="margin16">
            <span
              ><img
                src="/img/dataCenter/复议与应诉库@2x.png"
                alt=""
                style="width: 95px"
            /></span>
          </div>
          <div class="margin16">
            <span
              ><img
                src="/img/dataCenter/商事主体库@2x.png"
                alt=""
                style="width: 79px"
            /></span>
          </div>
        </div>
      </li>
      <li class="li6">
        <img
          src="/img/dataCenter/数据平台化@2x.png"
          alt=""
          style="width: 103px"
        />
      </li>
      <li class="li7">
        <span class="marginR36"
          ><img
            src="/img/dataCenter/数据治理平台@2x.png"
            alt=""
            style="width: 95px"
        /></span>
        <span class="marginR36"
          ><img
            src="/img/dataCenter/数据资产平台@2x.png"
            alt=""
            style="width: 95px"
        /></span>
        <span class="marginR36"
          ><img
            src="/img/dataCenter/数据计算引擎@2x.png"
            alt=""
            style="width: 95px"
        /></span>
        <span
          ><img
            src="/img/dataCenter/数据服务平台@2x.png"
            alt=""
            style="width: 95px"
        /></span>
      </li>
    </ul>
    <ul class="p3ul2">
      <li class="li8">
        <span>
          <img
            src="/img/dataCenter/一体化数据同步引擎@2x.png"
            alt=""
            style="width: 214px"
          />
        </span>
      </li>
      <li class="li9">
        <span>
          <img src="/img/dataCenter/数据源@2x.png" alt="" style="width: 60px" />
        </span>
      </li>
      <li class="li10">
        <div style="margin-bottom: 13px">
          <span class="marginR48"
            ><img
              src="/img/dataCenter/平台运行数据@2x.png"
              alt=""
              style="width: 95px"
          /></span>
          <span class="marginR48"
            ><img
              src="/img/dataCenter/自建执法系统数据@2x.png"
              alt=""
              style="width: 126px"
          /></span>
          <span
            ><img
              src="/img/dataCenter/政务大厅许可数据@2x.png"
              alt=""
              style="width: 126px"
          /></span>
        </div>
        <div>
          <span class="marginR48"
            ><img
              src="/img/dataCenter/行政复议数据@2x.png"
              alt=""
              style="width: 95px"
          /></span>
          <span class="marginR48"
            ><img
              src="/img/dataCenter/法院行政诉讼数据@2x.png"
              alt=""
              style="width: 126px"
          /></span>
          <span
            ><img
              src="/img/dataCenter/市场监管商事主体数据@2x.png"
              alt=""
              style="width: 159px"
          /></span>
        </div>
      </li>
    </ul> -->
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="less">
.part3-img {
  width: 1200px;
  -webkit-user-drag: none;
}
.marginR36 {
  margin-right: 36px;
}
.marginR48 {
  margin-right: 48px;
}
.marginR10 {
  margin-right: 10px;
}
.margin16 {
  margin-bottom: 16px;
}
.spanStyle {
  display: inline-block;
  background-color: #daebff;
  text-align: center;
  line-height: 53px;
  width: 163px;
  height: 50px;
}
.lastpStyle {
  position: relative;
  z-index: 9;
  line-height: 50px;
  margin-top: 21px;
  background-color: white;
  background: url('~@/assets/images/dataCenter/矩形@2x.png') no-repeat center;
  background-size: 100% 100%;
  img {
    vertical-align: middle;
  }
}
.p3outer {
  width: 1200px;
  margin: auto;
  position: relative;
  padding-bottom: 72px;
  // top: 1019px;
  // right: 0;
  // left: 0;
  .p3Title {
    text-align: center;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #25282b;
    line-height: 66px;
  }
  .p3titlebar {
    display: block;
    height: 3px;
    background: url('~@/assets/images/dataCenter/p2titlebar.png') no-repeat
      center;
    background-size: contain;
    margin-bottom: 30px;
  }
  .arrs {
    position: absolute;
    margin: auto;
    right: 0;
    left: 0;
    left: 500px;
    top: 896px;
    img {
      transform: scale(0.5);
      margin-right: 50px;
    }
  }
  ul {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 20px;
    box-sizing: border-box;
  }
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f8ff;
  }
  .p3ul2 {
    margin-top: 37px;
    height: 250px;
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      'b b b b b b b'
      'c d d d d d d'
      'c d d d d d d';
    .li8 {
      grid-area: b;
      background-color: #e0fcf7;
    }
    .li9 {
      grid-area: c;
    }
    .li10 {
      grid-area: d;
      flex-direction: column;
      span {
        .spanStyle;
        width: 256px;
      }
    }
    .li9,
    .li10 {
      background-color: #f4f8ff;
    }
  }
  .p3ul1 {
    height: 800px;
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
      'a b b b b b b'
      'c d d d d d d'
      'c d d d d d d'
      'c e e e e e e'
      'c e e e e e e'
      'f g g g g g g';

    .li2 {
      grid-area: b;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        margin-right: 32px;
        width: 200px;
        height: 50px;
        background-color: #daebff;
        justify-content: center;
        display: flex;
        align-items: center;
      }
    }
    .li3 {
      grid-area: c;
    }
    .li4 {
      grid-area: d;
      position: relative;
      color: #00e0c0;
      p {
        height: 50px;
        line-height: 53px;
        background-color: #daebff;
        .margin16;
      }

      .li4arr {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 153px;
        width: 30px !important;
      }
      img {
        transform: none;
      }

      & > div {
        margin-right: 42px;
        text-align: center;
      }
      .li4_1,
      .li4_2,
      .li4_3 {
        height: 224px;
      }
      .li4_1 {
        width: 203px;
        position: relative;
        div {
          margin-top: 16px;
          span {
            .spanStyle;
          }
        }
        .lastp {
          margin-bottom: 0px;
          .lastpStyle;
        }

        img {
          width: 47px;
        }
      }
      .li4_2 {
        position: relative;
        width: 336px;
        .lastp2 {
          span {
            .lastpStyle;
            margin-top: 7px;
            background-image: url('~@/assets/images/dataCenter/矩形2@2x.png');
          }
        }
        div {
          margin-top: 16px;
        }
        span {
          .spanStyle;
        }
        img {
          width: 111px;
        }
      }
      .li4_3 {
        position: relative;
        width: 336px;
        span {
          .spanStyle;
        }
        div {
          margin-top: 16px;
        }
        .lastp3 {
          span {
            width: 336px;
            .lastpStyle;
            margin-top: 6px;
            background-image: url('~@/assets/images/dataCenter/矩形2@2x.png');
          }
        }
      }
    }
    .li5 {
      grid-area: e;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        .spanStyle;
        width: 113px;
      }
      .li5f1,
      .li5f2,
      .li5f3,
      .li5f4 {
        height: 224px;
        text-align: center;
      }
      .li5f1,
      .li5f2 {
        margin-right: 45px;
      }
      .li5f1 {
        width: 236px;
      }
      .li5f2 {
        width: 359px;
      }
      .li5f3 {
        width: 113px;
        margin-right: 46px;
        span {
          .spanStyle;
          width: 113px;
        }
      }
      .li5f4 {
        width: 113px;
        span {
          .spanStyle;
          width: 113px;
        }
      }
    }
    .li7 {
      grid-area: g;
      span {
        .spanStyle;
        width: 197px;
      }
    }
  }
}
</style>
