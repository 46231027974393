import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-670afa2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-cont" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["part1"]),
    _createVNode($setup["part2"]),
    _createVNode($setup["part3"]),
    _createVNode($setup["ComBox"], { title: "典型案例" }, {
      default: _withCtx(() => [
        _createVNode($setup["part4"])
      ]),
      _: 1 /* STABLE */
    })
  ]))
}